var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tour-style h-100"},[(_vm.isPageLoading)?_c('LxpLoader',{staticClass:"h-100"}):_c('UserLayout',{ref:"userLayout",attrs:{"layout-type":2},on:{"startTour":_vm.startTour},scopedSlots:_vm._u([{key:"bottom-section",fn:function(){return [(_vm.isLoading)?_c('Loader'):_c('div',{class:[
          'h-100 vle-wrapper',
          _vm.getCurrentMenuItem,
          {
            'vle__overlay-fixed':
              _vm.getCurrentMenuItem != 'modules' && _vm.isNavOpen,
            'vle__sidenav--open':
              _vm.isVleSideNavOpen && _vm.getCurrentMenuItem !== 'modules',
            'vle-wrapper__scorm':
              _vm.isScormUnit && _vm.getCurrentMenuItem === 'modules'
          }
        ],attrs:{"id":"qa-self-paced-id"}},[_c('div',{staticClass:"sp__content-wrapper",class:{
            vle__success: _vm.isCourseCompleted,
            vle_forums: _vm.getCurrentMenuItem === 'forums'
          }},[_c('Header',{on:{"goToCourseDetail":_vm.goToCourseDetail,"onNavClick":_vm.onNavClick,"selectedCategory":_vm.handleCategoryName}}),(_vm.getCurrentMenuItem == 'forums')?_c('div',{staticClass:"right__nav--wrapper"},[_c('PostList',{attrs:{"selectedCategoryName":_vm.selectedCategoryName}})],1):_vm._e()],1)])]},proxy:true}])}),_c('VueTour',{attrs:{"steps":_vm.steps,"myCallbacks":_vm.myCallbacks},on:{"bulletClick":_vm.myCustomNextStepCallback,"repeatTour":_vm.repeatTour,"closeTour":_vm.closeTour}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }